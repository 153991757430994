import request from '@/common/request'

class UsuarioService {
  buscarTudo() {
    return request.get('/usuarios')
  }

  buscarPeloId(id) {
    return request.get(`/usuarios/${id}`)
  }

  cadastrar(data) {
    return request.post('/usuarios', data)
  }

  atualizar(id, data) {
    return request.put(`/usuarios/${id}`, data)
  }

  excluir(id) {
    return request.delete(`/usuarios/${id}`)
  }
}

export default new UsuarioService()
