<template>
  <v-container fluid>
    <v-card class="mt-2">
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :loading="buscando"
          loading-text="Carregando os usuários..."
          :items="usuarios"
          item-class="class"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
        >
          <template #top>
            <v-container fluid class="d-flex">
              <v-toolbar-title> Usuários </v-toolbar-title>

              <v-spacer />

              <v-dialog v-model="dialog" max-width="550px">
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" :disabled="!permiteAdicionar" v-on="on">
                    Adicionar
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="validForm" @submit.prevent>
                      <v-container>
                        <v-row>
                          <v-col v-if="editedIndex !== -1" cols="6">
                            <v-switch v-model="editedItem.ativo" color="green" label="Ativo" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.nome"
                              label="Nome"
                              required
                              :rules="[obrigatorio]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              v-model="editedItem.perfil.id"
                              :items="itensPerfil"
                              no-data-text="Nenhum perfil encontrado"
                              :rules="[obrigatorio]"
                              label="Perfil"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.login"
                              label="Login"
                              required
                              :rules="[obrigatorio]"
                              :disabled="editedIndex > -1"
                              :suffix="editedIndex > -1 ? '' : organizacaoNome"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.senha"
                              label="Senha"
                              required
                              type="password"
                              autocomplete="off"
                              :rules="editedIndex <= -1 ? [obrigatorio] : []"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.email"
                              label="E-mail"
                              required
                              :rules="[obrigatorio, emailInvalido]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert v-if="showMessageError" dense outlined type="error">
                              {{ messageError }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="fecharDialog">Cancelar</v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      :disabled="!validForm"
                      :loading="salvando"
                      @click="salvarUsuario"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
          </template>

          <template #[`item.ativo`]="{ item }">
            <v-icon v-if="item.ativo" color="green">mdi-check</v-icon>
            <v-icon v-else color="red">mdi-close</v-icon>
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteEditar"
                    v-on="on"
                    @click="editarUsuario(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span> Editar </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </v-container>
</template>

<script>
import UsuarioService from '@/services/usuario/usuario'
import OrganizacaoService from '@/services/organizacao/organizacao'
import PerfilService from '@/services/usuario/perfil'
import Snackbar from '@/models/ui/snackbar'
import Usuario from '@/models/usuario/usuario'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import { obrigatorio, emailInvalido } from '@/shareds/regrasForm'
import store from '@/store'

export default {
  name: 'Usuario',

  components: {
    SnackbarMessage
  },

  data: () => ({
    usuarios: [],
    itensPerfil: [],
    headers: [
      { text: 'Nome', value: 'nome' },
      { text: 'Login', value: 'login' },
      { text: 'E-mail', value: 'email' },
      { text: 'Ativo', value: 'ativo' },
      { text: 'Perfil', value: 'perfil.nome' },
      { text: '', value: 'acoes', width: '1%' }
    ],
    panel: true,
    buscando: false,
    salvando: false,
    dialog: false,
    editedItem: new Usuario(),
    editedIndex: -1,
    validForm: true,
    obrigatorio: obrigatorio,
    emailInvalido: emailInvalido,
    showMessageError: false,
    snackbar: new Snackbar()
  }),

  organizacaoNome: '',

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Adicionar usuário' : 'Editar usuário'
    },
    permiteAdicionar() {
      return store.getters.verificaPermissaoAcesso('Usuario', 'ADICIONAR')
    },
    permiteEditar() {
      return store.getters.verificaPermissaoAcesso('Usuario', 'EDITAR')
    }
  },

  created() {
    this.buscarUsuarios()
    this.buscarPerfis()
    this.buscarOrganizacao()
  },

  methods: {
    buscarUsuarios() {
      this.buscando = true

      UsuarioService.buscarTudo()
        .then((response) => {
          this.usuarios = response
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os perfis.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscando = false))
    },

    buscarPerfis() {
      PerfilService.buscarTudo(false)
        .then(
          (response) =>
            (this.itensPerfil = response.map((item) => ({
              text: item.nome,
              value: item.id
            })))
        )
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os perfis.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    buscarOrganizacao() {
      this.buscando = true

      OrganizacaoService.buscarPeloToken()
        .then((response) => {
          this.organizacao = response
          if (typeof this.organizacao.nome === 'string') {
            this.organizacaoNome = '.' + this.organizacao.nome.toLowerCase()
          }
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar as organizações.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscando = false))
    },

    editarUsuario(item) {
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = { ...item, senha: '' }
      this.dialog = true
    },

    salvarUsuario() {
      this.showMessageError = false
      this.$refs.form.validate()

      if (!this.validForm) return

      this.salvando = true

      this.editedItem.login = this.editedItem.login.toLowerCase()
      this.editedItem.email = this.editedItem.email.toLowerCase()

      if (this.editedIndex > -1) {
        UsuarioService.atualizar(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.fecharDialog()
            this.snackbar.snackTextParam = 'Usuário atualizado com sucesso.'
            this.snackbar.snackTypeParam = 'sucess'
            this.snackbar.showSnackParam = true
            Object.assign(this.usuarios[this.editedIndex], response)
          })
          .catch((error) => {
            this.messageError = `${error.response.status} | ${
              error.response.data.message === undefined
                ? error.message
                : error.response.data.message
            }`
            this.showMessageError = true
          })
          .finally(() => (this.salvando = false))
      } else {
        UsuarioService.cadastrar({
          ...this.editedItem,
          login: this.editedItem.login + this.organizacaoNome
        })
          .then((response) => {
            this.fecharDialog()
            this.snackbar.snackTextParam = 'Usuário cadastrado com sucesso.'
            this.snackbar.snackTypeParam = 'sucess'
            this.snackbar.showSnackParam = true
            this.usuarios.push(response)
          })
          .catch((error) => {
            this.messageError = `${error.response.status} | ${
              error.response.data.message === undefined
                ? error.message
                : error.response.data.message
            }`
            this.showMessageError = true
          })
          .finally(() => (this.salvando = false))
      }
    },

    fecharDialog() {
      this.$refs.form.resetValidation()
      this.dialog = false
      this.showMessageError = false
      this.$nextTick(() => {
        this.editedItem = new Usuario()
        this.editedIndex = -1
      })
    }
  }
}
</script>
