import Perfil from './perfil'

export default class Usuario {
  constructor(nomeUsuario, login, senha) {
    this.nomeUsuario = nomeUsuario
    this.login = login
    this.senha = senha
    this.perfil = new Perfil()
  }
}
